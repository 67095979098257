var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"},{name:"bg",rawName:"v-bg",value:({
      remoteSrc: _vm.imgList.backgroundImg,
      localSrc: require('@/assets/home-bg.png')
    }),expression:"{\n      remoteSrc: imgList.backgroundImg,\n      localSrc: require('@/assets/home-bg.png')\n    }"}],staticClass:"turntable-box"},[_c('div',{staticClass:"outer"},[_c('img',{directives:[{name:"bg",rawName:"v-bg",value:({
          remoteSrc: _vm.imgList.prizePlateConfig.outerImg,
          localSrc: require('@/assets/turntable-outer.png')
        }),expression:"{\n          remoteSrc: imgList.prizePlateConfig.outerImg,\n          localSrc: require('@/assets/turntable-outer.png')\n        }"}],attrs:{"alt":""}}),_c('div',{staticClass:"inner",attrs:{"id":"turnUl"}},[_c('img',{directives:[{name:"bg",rawName:"v-bg",value:({
            remoteSrc: _vm.imgList.prizePlateConfig.innnerImg,
            localSrc: require('@/assets/turntable-inner.png')
          }),expression:"{\n            remoteSrc: imgList.prizePlateConfig.innnerImg,\n            localSrc: require('@/assets/turntable-inner.png')\n          }"}],attrs:{"alt":""}}),_c('ul',{staticClass:"prize",style:({ webkitTransform: 'rotate(' + -_vm.base + 'deg)' })},_vm._l((_vm.prizeList),function(item,index){return _c('li',{key:index,style:({ webkitTransform: 'rotate(' + -item.angle + 'deg)' })},[_c('img',{attrs:{"src":item.prizeImg,"alt":""}})])}),0)]),_c('div',{directives:[{name:"tracking",rawName:"v-tracking",value:({ userVisit: '3' }),expression:"{ userVisit: '3' }"}],staticClass:"pointer",on:{"click":function($event){return _vm.startrotate()}}},[_c('img',{directives:[{name:"bg",rawName:"v-bg",value:({
            remoteSrc: _vm.imgList.pointImg,
            localSrc: require('@/assets/turntable-pointer.png')
          }),expression:"{\n            remoteSrc: imgList.pointImg,\n            localSrc: require('@/assets/turntable-pointer.png')\n          }"}],attrs:{"alt":""}})])]),_c('div',{staticClass:"question"},[_c('p',{staticClass:"item",on:{"click":function($event){return _vm.questionHref()}}},[_c('img',{attrs:{"src":require("../../assets/wtzx.png"),"alt":""}})]),_c('p',{staticClass:"item",on:{"click":function($event){return _vm.onProtocol()}}},[_c('img',{attrs:{"src":require("../../assets/yssm.png"),"alt":""}})])]),_c('div',{staticClass:"winning"},[_c('img',{directives:[{name:"bg",rawName:"v-bg",value:({
          remoteSrc: _vm.imgList.prizeFormImg,
          localSrc: require('@/assets/winners.png')
        }),expression:"{\n          remoteSrc: imgList.prizeFormImg,\n          localSrc: require('@/assets/winners.png')\n        }"}],attrs:{"alt":""}}),_c('div',{staticClass:"winningBox"},[_c('vue-seamless-scroll',{staticClass:"seamless-warp",attrs:{"data":_vm.winningList,"class-option":_vm.classOption}},[_c('ul',{staticClass:"winningList"},_vm._l((_vm.winningList),function(item,index){return _c('li',{key:index},[_c('span',[_vm._v(_vm._s(item.prizeName))]),_c('span',[_vm._v(_vm._s(item.createTime))])])}),0)])],1)]),_c('div',{staticClass:"rule"},[_c('div',{staticClass:"rule-box"},[_c('baseActivityRules')],1)]),_vm._m(0)]),_c('assistant',{directives:[{name:"tracking",rawName:"v-tracking",value:({ userVisit: '8' }),expression:"{ userVisit: '8' }"}],on:{"click":_vm.showFather}}),_c('winningRecord',{attrs:{"recordShow":_vm.recordShow,"recordList":_vm.recordList},on:{"click":_vm.showFather}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"support"},[_c('img',{attrs:{"src":require("../../assets/jszc.png"),"alt":""}})])}]

export { render, staticRenderFns }